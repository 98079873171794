import React, { useEffect } from 'react';
import { Box, Typography, Paper, Container, Grid } from '@mui/material';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const Resume = () => {
    const [experienceRef, experienceInView] = useInView({ triggerOnce: false, threshold: 0.3 });
    const [educationRef, educationInView] = useInView({ triggerOnce: false, threshold: 0.3 });

    const experienceControls = useAnimation();
    const educationControls = useAnimation();

    // Animation for experience section
    useEffect(() => {
        if (experienceInView) {
            experienceControls.start({
                opacity: 1,
                y: 0,
                transition: { duration: 0.6, ease: 'easeOut' },
            });
        } else {
            experienceControls.start({ opacity: 0, y: 50 });
        }
    }, [experienceInView, experienceControls]);

    // Animation for education section
    useEffect(() => {
        if (educationInView) {
            educationControls.start({
                opacity: 1,
                y: 0,
                transition: { duration: 0.6, ease: 'easeOut' },
            });
        } else {
            educationControls.start({ opacity: 0, y: 50 });
        }
    }, [educationInView, educationControls]);

    return (
        <Box
            id="section-resume"
            sx={{
                padding: 4,
                background: 'linear-gradient(to right, #000, #000)',
                color: '#FFBD38',
            }}
        >
            <Container>
                {/* Experience Section */}
                <Typography
                    variant="h3"
                    gutterBottom
                    sx={{
                        textAlign: 'center',
                        marginBottom: 4,
                        fontFamily: 'Poppins, sans-serif',
                        fontWeight: 'bold',
                        fontSize: { xs: '2rem', md: '2.5rem' },
                        letterSpacing: '1.5px',
                        color: '#FFBD38',
                        textShadow: '2px 2px 5px rgba(0, 0, 0, 0.7)',
                    }}
                >
                    Experience
                </Typography>

                <motion.div
                    ref={experienceRef}
                    animate={experienceControls}
                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} // Center items
                >
                    <Grid container spacing={3} justifyContent="center">
                        {/* Experience 1 */}
                        <Grid item xs={12} sm={6}>
                            <motion.div whileHover={{ scale: 1.05 }}>
                                <Paper
                                    elevation={12}
                                    sx={{
                                        padding: 4,
                                        backgroundColor: '#000',
                                        color: '#FFBD38',
                                        borderRadius: 4,
                                        boxShadow: '0 0 20px rgba(255, 189, 56, 0.7)',
                                        position: 'relative',
                                        overflow: 'hidden',
                                        '&:before': {
                                            content: '""',
                                            position: 'absolute',
                                            top: 0,
                                            right: 0,
                                            bottom: 0,
                                            left: 0,
                                            borderRadius: 4,
                                            background: 'rgba(255, 189, 56, 0.1)',
                                            zIndex: 0,
                                        },
                                    }}
                                >
                                    <span style={{ color: '#FFBD38' }}>Sept 2023 - Ongoing</span>
                                    <Typography variant="h5" sx={{ fontWeight: 'bold', mt: 2, color: '#FFBD38', zIndex: 1 }}>
                                        Opstree Solutions
                                    </Typography>
                                    <span style={{ color: '#FFF', zIndex: 1 }}>Rupeek (DevOps Specialist)</span>
                                    <Typography sx={{ color: '#FFF', mt: 2, zIndex: 1 }}>
                                        Verizon Cloud Platform Job Simulation on Forage - March 2024
                                    </Typography>
                                    <ul style={{ color: '#FFF', zIndex: 1 }}>
                                        <li>Completed a job simulation involving building a hypothetical new VPN product for Verizon’s Cloud Computing team.</li>
                                        <li>Used command line Python to test whether Verizon’s VPN met the cloud-native traits, i.e., redundancy, resiliency, and least-privilege.</li>
                                        <li>Researched approaches to achieve application security and communicated insights in a PowerPoint Presentation.</li>
                                    </ul>
                                </Paper>
                            </motion.div>
                        </Grid>

                        {/* Experience 2 */}
                        <Grid item xs={12} sm={6}>
                            <motion.div whileHover={{ scale: 1.05 }}>
                                <Paper
                                    elevation={12}
                                    sx={{
                                        padding: 4,
                                        backgroundColor: '#000',
                                        color: '#FFBD38',
                                        borderRadius: 4,
                                        boxShadow: '0 0 20px rgba(255, 189, 56, 0.7)',
                                        position: 'relative',
                                        overflow: 'hidden',
                                        '&:before': {
                                            content: '""',
                                            position: 'absolute',
                                            top: 0,
                                            right: 0,
                                            bottom: 0,
                                            left: 0,
                                            borderRadius: 4,
                                            background: 'rgba(255, 189, 56, 0.1)',
                                            zIndex: 0,
                                        },
                                    }}
                                >
                                    <span style={{ color: '#FFBD38' }}>Sept 2020 - Aug 2023</span>
                                    <Typography variant="h5" sx={{ fontWeight: 'bold', mt: 2, color: '#FFBD38', zIndex: 1 }}>
                                        Capgemini India Pvt Ltd
                                    </Typography>
                                    <span style={{ color: '#FFF', zIndex: 1 }}>IKEA (Associate Consultant)</span>
                                    <Typography sx={{ color: '#FFF', mt: 2, zIndex: 1 }}>
                                        Spearheaded the Range Rollout project, successfully resolving issues and incidents to ensure seamless development and deployment processes.
                                    </Typography>
                                    <ul style={{ color: '#FFF', zIndex: 1 }}>
                                        <li>Managed Identity and Access Management using Keycloak and implemented CI/CD pipelines with Jenkins, Docker, and Kubernetes to enhance application deployment efficiency.</li>
                                        <li>Led the Range Excellence Team at IKEA, providing cross-functional support and solutions.</li>
                                        <li>Collaborated effectively to improve software performance and streamline workflows.</li>
                                    </ul>
                                </Paper>
                            </motion.div>
                        </Grid>
                    </Grid>
                </motion.div>

                <br />
                <br />
                <br />
                <br />

                {/* Education Section */}
                <Typography variant="h4" sx={{ textAlign: 'center', marginBottom: 2, color: '#FFBD38' }}>
                    Education
                </Typography>
                <div className="underline" style={{ width: '50%', margin: '0 auto', borderBottom: '2px solid #FFBD38' }}></div>

                <motion.div
                    ref={educationRef}
                    initial={{ opacity: 0, y: 50 }}
                    animate={educationControls}
                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }} // Center items
                >
                    <Grid container spacing={3} justifyContent="center">
                        {/* Education 1 */}
                        <Grid item xs={12} sm={6}>
                            <motion.div whileHover={{ scale: 1.05 }}>
                                <Paper
                                    elevation={12}
                                    sx={{
                                        padding: 4,
                                        backgroundColor: '#000',
                                        color: '#FFBD38',
                                        borderRadius: 4,
                                        boxShadow: '0 0 20px rgba(255, 189, 56, 0.7)',
                                        position: 'relative',
                                        overflow: 'hidden',
                                        '&:before': {
                                            content: '""',
                                            position: 'absolute',
                                            top: 0,
                                            right: 0,
                                            bottom: 0,
                                            left: 0,
                                            borderRadius: 4,
                                            background: 'rgba(255, 189, 56, 0.1)',
                                            zIndex: 0,
                                        },
                                    }}
                                >
                                    <span style={{ color: '#FFBD38' }}>2016-2020</span>
                                    <Typography variant="h5" sx={{ fontWeight: 'bold', mt: 2, color: '#FFBD38', zIndex: 1 }}>
                                        Bachelor of Technology (Electronics Engineering)
                                    </Typography>
                                    <span style={{ color: '#FFF', zIndex: 1 }}>Veermata Jijabai Technological Institute</span>
                                    <Typography sx={{ color: '#FFF', mt: 2, zIndex: 1 }}>Passed with distinction</Typography>
                                </Paper>
                            </motion.div>
                        </Grid>

                        {/* Education 2 */}
                        <Grid item xs={12} sm={6}>
                            <motion.div whileHover={{ scale: 1.05 }}>
                                <Paper
                                    elevation={12}
                                    sx={{
                                        padding: 4,
                                        backgroundColor: '#000',
                                        color: '#FFBD38',
                                        borderRadius: 4,
                                        boxShadow: '0 0 20px rgba(255, 189, 56, 0.7)',
                                        position: 'relative',
                                        overflow: 'hidden',
                                        '&:before': {
                                            content: '""',
                                            position: 'absolute',
                                            top: 0,
                                            right: 0,
                                            bottom: 0,
                                            left: 0,
                                            borderRadius: 4,
                                            background: 'rgba(255, 189, 56, 0.1)',
                                            zIndex: 0,
                                        },
                                    }}
                                >
                                    <span style={{ color: '#FFBD38' }}>2014-2016</span>
                                    <Typography variant="h5" sx={{ fontWeight: 'bold', mt: 2, color: '#FFBD38', zIndex: 1 }}>
                                        Higher Secondary Education (Science)
                                    </Typography>
                                    <span style={{ color: '#FFF', zIndex: 1 }}>Maharashtra State Board</span>
                                    <Typography sx={{ color: '#FFF', mt: 2, zIndex: 1 }}>Passed with distinction</Typography>
                                </Paper>
                            </motion.div>
                        </Grid>
                    </Grid>
                </motion.div>
            </Container>
        </Box>
    );
};

export default Resume;
