import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, Box, useMediaQuery, IconButton, Fade } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Link as ScrollLink } from 'react-scroll';

const Navbar = () => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setMenuOpen((prev) => !prev);
  };

  const navItems = ['home-section', 'about-section', 'project-section', 'contact-section'];

  return (
    <AppBar
      position="sticky"
      sx={{
        background: menuOpen ? '#000' : '#000',
        boxShadow: 'none',
        padding: 1,
        transition: 'background 0.3s ease',
      }}
    >
      <Toolbar>
        <Typography
          variant="h6"
          sx={{
            flexGrow: 1,
            fontWeight: 'bold',
            color: '#FFBD38',
            transition: 'color 0.3s ease',
            '&:hover': { color: '#FFC107' },
          }}
        >
          My Portfolio
        </Typography>

        {isSmallScreen ? (
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleMenuToggle}
            aria-label="menu"
          >
            {menuOpen ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
        ) : (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {navItems.map((section) => (
              <Button key={section} color="inherit">
                <ScrollLink
                  to={section}
                  smooth
                  duration={500}
                  style={{ color: '#FFBD38', transition: 'color 0.3s ease' }}
                  onMouseEnter={(e) => (e.target.style.color = '#FFC107')}
                  onMouseLeave={(e) => (e.target.style.color = '#FFBD38')}
                >
                  {section.replace('-', ' ').replace('section', '').toUpperCase()}
                </ScrollLink>
              </Button>
            ))}
          </Box>
        )}
      </Toolbar>

      {/* Mobile Menu */}
      {isSmallScreen && (
        <Fade in={menuOpen}>
          <Box
            sx={{
              display: menuOpen ? 'flex' : 'none',
              flexDirection: 'column',
              alignItems: 'flex-start',
              padding: 2,
              bgcolor: 'rgba(0, 0, 0, 0.9)',
              position: 'absolute',
              top: '64px',
              left: 0,
              right: 0,
              zIndex: 1000,
              borderTop: '1px solid #FFBD38',
              transition: 'max-height 0.3s ease',
            }}
          >
            {navItems.map((section) => (
              <Button key={section} color="inherit">
                <ScrollLink
                  to={section}
                  smooth
                  duration={500}
                  style={{ color: '#FFBD38', transition: 'color 0.3s ease' }}
                  onClick={() => setMenuOpen(false)}
                  onMouseEnter={(e) => (e.target.style.color = '#FFC107')}
                  onMouseLeave={(e) => (e.target.style.color = '#FFBD38')}
                >
                  {section.replace('-', ' ').replace('section', '').toUpperCase()}
                </ScrollLink>
              </Button>
            ))}
          </Box>
        </Fade>
      )}
    </AppBar>
  );
};

export default Navbar;
