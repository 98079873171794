import React from 'react';
import { Box, Typography, Link, Container } from '@mui/material';

const Footer = () => {
  return (
    <Box sx={{ backgroundColor: '#333', color: '#FFD700', padding: 2, textAlign: 'center' }}>
      <Container>
        <Typography variant="body2">
          &copy; {new Date().getFullYear()} All rights reserved | This template is made with <span role="img" aria-label="heart">React</span> by <Link href="https://colorlib.com" target="_blank" color="inherit">srjangale</Link>
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
