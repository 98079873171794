import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Paper,
  TextField,
  Button,
  Container,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { motion } from 'framer-motion';
import { Email, Phone, LocationOn } from '@mui/icons-material';

const Contact = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // For mobile
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md')); // For tablet

  const hoverAnimation = {
    hover: {
      scale: isMobile ? 1.02 : 1.05, // Scale less on mobile
      boxShadow: '0 12px 24px rgba(0, 0, 0, 0.4)',
      transition: { duration: 0.3 },
    },
  };

  const formAnimation = {
    hover: {
      scale: 1.05,
      boxShadow: '0 12px 24px rgba(0, 0, 0, 0.4)',
      transition: { duration: 0.3 },
    },
  };

  

  return (
    <Box
      id="contact-section"
      sx={{
        padding: isMobile ? 4 : 6,
        background: 'linear-gradient(to right, #000, #000)',
        color: '#FFBD38',
      }}
    >
      <Container>
        <Typography
          variant={isMobile ? 'h4' : 'h3'}
          gutterBottom
          sx={{
            textAlign: 'center',
            marginBottom: isMobile ? 3 : 4,
            fontFamily: 'Poppins, sans-serif',
            fontWeight: 'bold',
            fontSize: isMobile ? '2rem' : '2.5rem',
            letterSpacing: '1.5px',
            color: '#FFBD38',
            textShadow: '2px 2px 5px rgba(0, 0, 0, 0.7)',
          }}
        >
          Contact Me
        </Typography>
        <Grid container spacing={isMobile ? 2 : 4}>
          {/* Address Section */}
          <Grid item xs={12} md={6}>
            <motion.div whileHover="hover" variants={hoverAnimation}>
              <Paper
                elevation={12}
                sx={{
                  padding: isMobile ? 3 : 4,
                  backgroundColor: '#222',
                  color: '#FFBD38',
                  borderRadius: 4,
                  position: 'relative',
                  overflow: 'hidden',
                  '&:hover': {
                    transform: 'scale(1.02)',
                    transition: 'transform 0.3s ease',
                  },
                  boxShadow: '0 0 15px rgba(255, 189, 56, 0.4)',
                }}
              >
                <Typography
                  variant={isMobile ? 'h6' : 'h5'}
                  sx={{
                    marginBottom: 2,
                    fontFamily: 'Poppins, sans-serif',
                    fontWeight: 'bold',
                    fontSize: '1.8rem',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <LocationOn sx={{ marginRight: 1, color: '#FFBD38' }} /> Get In Touch
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    marginBottom: 1,
                    fontSize: '1.2rem',
                    fontFamily: 'Roboto, sans-serif',
                  }}
                >
                  Address: <span style={{ fontWeight: '600' }}>Nashik, India</span>
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    marginBottom: 1,
                    fontSize: '1.2rem',
                    fontFamily: 'Roboto, sans-serif',
                  }}
                >
                  Contact Number: <span style={{ fontWeight: '600' }}>+91 9987646236</span>
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    marginBottom: 1,
                    fontSize: '1.2rem',
                    fontFamily: 'Roboto, sans-serif',
                  }}
                >
                  Email: <span style={{ fontWeight: '600' }}>shubhamjangle8@gmail.com</span>
                </Typography>
              </Paper>
            </motion.div>
          </Grid>

          {/* Message Section */}
          <Grid item xs={12} md={6}>
      <motion.div whileHover="hover" variants={formAnimation}>
        <Paper
          elevation={12}
          sx={{
            padding: isMobile ? 2 : isTablet ? 3 : 4, // Adjust padding for different screen sizes
            backgroundColor: '#222',
            color: '#FFBD38',
            borderRadius: 4,
            position: 'relative',
            overflow: 'hidden',
            '&:hover': {
              transform: 'scale(1.02)',
              transition: 'transform 0.3s ease',
            },
            boxShadow: '0 0 15px rgba(255, 189, 56, 0.4)', // Whitish glow effect
          }}
        >
          <Typography
            variant={isMobile ? 'h6' : 'h5'} // Adjust typography variant for mobile
            sx={{
              marginBottom: 2,
              fontFamily: 'Poppins, sans-serif',
              fontWeight: 'bold',
              fontSize: isMobile ? '1.5rem' : '1.8rem', // Adjust font size
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Email sx={{ marginRight: 1, color: '#FFBD38' }} /> Send a Message
          </Typography>
          <TextField
            fullWidth
            label="Name"
            margin="normal"
            sx={{
              input: { color: '#FFFFFF' },
              '& .MuiInputLabel-root': { color: '#FFBD38' },
              '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                borderColor: '#FFBD38',
              },
              '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#FFBD38',
              },
              '& .MuiInputLabel-root.Mui-focused': { color: '#FFBD38' },
              '&::placeholder': { color: '#FFFFFF', opacity: 0.8 },
            }}
            placeholder="Enter your name"
          />
          <TextField
            fullWidth
            label="Email"
            margin="normal"
            sx={{
              input: { color: '#FFFFFF' },
              '& .MuiInputLabel-root': { color: '#FFBD38' },
              '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                borderColor: '#FFBD38',
              },
              '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#FFBD38',
              },
              '& .MuiInputLabel-root.Mui-focused': { color: '#FFBD38' },
              '&::placeholder': { color: '#FFFFFF', opacity: 0.8 },
            }}
            placeholder="Enter your email"
          />
          <TextField
            fullWidth
            label="Message"
            multiline
            rows={isMobile ? 3 : 4} // Adjust the number of rows for mobile
            margin="normal"
            sx={{
              input: { color: '#FFFFFF' },
              '& .MuiInputLabel-root': { color: '#FFBD38' },
              '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                borderColor: '#FFBD38',
              },
              '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#FFBD38',
              },
              '& .MuiInputLabel-root.Mui-focused': { color: '#FFBD38' },
              '&::placeholder': { color: '#FFFFFF', opacity: 0.8 },
            }}
            placeholder="Type your message here..."
          />
          <motion.div whileHover={{ scale: 1.05 }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#FFBD38',
                color: '#000',
                marginTop: 2,
                fontWeight: 'bold',
                fontSize: isMobile ? '0.9rem' : '1rem', // Adjust font size for button
                padding: isMobile ? '6px 12px' : '8px 16px', // Adjust padding for button
                '&:hover': { backgroundColor: '#f4c300' },
                transition: 'transform 0.2s',
              }}
            >
              <a
                href="https://forms.gle/mjLBakxajcN7LKma7"
                style={{ color: '#000', textDecoration: 'none' }}
              >
                Send
              </a>
            </Button>
          </motion.div>
        </Paper>
      </motion.div>
    </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Contact;
