import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, Button, Container, IconButton } from '@mui/material';
import { motion } from 'framer-motion';
import { ArrowForward, GitHub, LinkedIn, Twitter, Instagram } from '@mui/icons-material';

const Hero = () => {
  const [animate, setAnimate] = useState(false);
  const heroRef = useRef(null);

  // Function to handle intersection changes
  const handleIntersection = (entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        setAnimate(true); // Trigger animation
      } else {
        setAnimate(false); // Stop animation when not in view
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.1 // Trigger when at least 10% of the component is visible
    });

    if (heroRef.current) {
      observer.observe(heroRef.current);
    }

    return () => {
      if (heroRef.current) {
        observer.unobserve(heroRef.current);
      }
    };
  }, [heroRef]);

  return (
    <Box
      id="home-section"
      ref={heroRef}
      sx={{
        height: { xs: 'auto', md: '100vh' }, // Adjust height for small screens
        backgroundImage: 'url(images/shu.1.jpeg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff',
        textAlign: 'center',
        position: 'relative',
        overflow: 'hidden',
        background: 'linear-gradient(135deg, #000000 0%, #434343 100%)',
        padding: { xs: '20px', md: 0 }, // Add padding on smaller screens
      }}
    >
      <motion.div
        initial={{ scale: 1.2 }}
        animate={{ scale: 1 }}
        transition={{ duration: 10, repeat: Infinity, repeatType: 'reverse' }}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'rgba(0, 0, 0, 0.5)',
          zIndex: 1,
        }}
      />
      <Container sx={{ position: 'relative', zIndex: 2, padding: { xs: '0 10px', md: 0 } }}>
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={animate ? { opacity: 1, y: 0 } : { opacity: 0, y: -50 }}
          transition={{ duration: 0.8, delay: 0.3 }}
        >
          <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#FFBD38', fontSize: { xs: '2rem', md: '3rem' } }}>
            Hello! I'm Shubham Jangale
          </Typography>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={animate ? { opacity: 1, y: 0 } : { opacity: 0, y: -50 }}
          transition={{ duration: 0.8, delay: 0.5 }}
        >
          <Typography variant="h6" sx={{ marginBottom: 3, color: '#e0e0e0', fontSize: { xs: '1.2rem', md: '1.5rem' } }}>
            Passionate about building scalable applications and cloud solutions
          </Typography>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={animate ? { opacity: 1, scale: 1 } : { opacity: 0, scale: 0.8 }}
          transition={{ duration: 0.8, delay: 0.7 }}
        >
          <Typography variant="h5" sx={{ marginBottom: 3, color: '#e0e0e0', fontSize: { xs: '1.5rem', md: '2rem' } }}>
            DevOps Engineer
          </Typography>
        </motion.div>

        <motion.div
          initial={{ scale: 0.9 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.5, type: 'spring', stiffness: 300 }}
          whileHover={{ scale: 1.05 }}
        >
          <Button
            variant="contained"
            sx={{
              background: 'linear-gradient(90deg, #FFBD38, #FFBD38)',
              color: '#000',
              padding: '12px 24px',
              borderRadius: '8px',
              boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.3)',
              '&:hover': {
                background: 'linear-gradient(90deg, #FFBD38, #FFBD38)',
                boxShadow: '0px 6px 30px rgba(0, 0, 0, 0.4)',
              },
              fontSize: { xs: '0.9rem', md: '1rem' }
            }}
            href="https://learnwithShubham.hashnode.dev/"
          >
            <ArrowForward sx={{ marginRight: 1 }} /> Blogs
          </Button>
        </motion.div>

        <Box sx={{ margin: '20px 0' }} />

        <motion.div
          initial={{ scale: 0.9 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.5, type: 'spring', stiffness: 300, delay: 0.1 }}
          whileHover={{ scale: 1.05 }}
        >
          <Button
            variant="outlined"
            sx={{
              borderColor: '#FFBD38',
              color: '#FFBD38',
              padding: '12px 24px',
              borderRadius: '8px',
              boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.3)',
              '&:hover': {
                background: 'rgba(255, 189, 56, 0.1)',
                borderColor: '#FFC107',
                boxShadow: '0px 6px 30px rgba(0, 0, 0, 0.4)',
              },
              fontSize: { xs: '0.9rem', md: '1rem' }
            }}
            href="https://github.com/ShubhamJangle8"
          >
            <ArrowForward sx={{ marginRight: 1 }} /> My Works
          </Button>
        </motion.div>

        <Box sx={{ marginTop: 4 }}>
          <Typography variant="h6" sx={{ color: '#FFBD38' }}>Connect with me:</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
            <IconButton href="https://github.com/ShubhamJangle8" target="_blank" sx={{ color: '#FFBD38', margin: '0 5px' }}>
              <GitHub />
            </IconButton>
            <IconButton href="https://www.linkedin.com/in/shubham-jangale/" target="_blank" sx={{ color: '#FFBD38', margin: '0 5px' }}>
              <LinkedIn />
            </IconButton>
            <IconButton href="https://twitter.com/ShubhamJangle8" target="_blank" sx={{ color: '#FFBD38', margin: '0 5px' }}>
              <Twitter />
            </IconButton>
            <IconButton href="https://www.instagram.com/sr_jangale/" target="_blank" sx={{ color: '#FFBD38', margin: '0 5px' }}>
              <Instagram />
            </IconButton>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Hero;
