import React, { useEffect } from 'react';
import { Box, Typography, Paper, Container, LinearProgress } from '@mui/material';
import { motion, useAnimation } from 'framer-motion';
import { FaClock, FaCloud, FaCogs, FaGithub, FaDocker } from 'react-icons/fa'; // Font Awesome icons
import { useInView } from 'react-intersection-observer';

// Custom Linear Progress component
const CustomLinearProgress = ({ value }) => (
  <Box sx={{ position: 'relative', height: '8px', borderRadius: '5px', backgroundColor: '#333' }}>
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: `${value}%`,
        backgroundColor: '#FFBD38',
        borderRadius: '5px',
        transition: 'width 0.4s ease-in-out',
      }}
    />
  </Box>
);

const About = () => {
  const [aboutMeRef, aboutMeInView] = useInView({ triggerOnce: false, threshold: 0.3 });
  const [skillsRef, skillsInView] = useInView({ triggerOnce: false, threshold: 0.3 });
  const [counterRef, counterInView] = useInView({ triggerOnce: false, threshold: 0.3 });
  const [hireMeRef, hireMeInView] = useInView({ triggerOnce: false, threshold: 0.3 });

  const aboutMeControls = useAnimation();
  const skillsControls = useAnimation();
  const counterControls = useAnimation();
  const hireMeControls = useAnimation();

  // About Me animation
  useEffect(() => {
    if (aboutMeInView) {
      aboutMeControls.start({ opacity: 1, y: 0, transition: { duration: 0.8 } });
    } else {
      aboutMeControls.start({ opacity: 0, y: 20 }); // Reset when out of view
    }
  }, [aboutMeInView, aboutMeControls]);

  // Skills animation
  useEffect(() => {
    if (skillsInView) {
      skillsControls.start({ opacity: 1, x: 0, transition: { duration: 0.8 } });
    } else {
      skillsControls.start({ opacity: 0, x: -20 }); // Reset when out of view
    }
  }, [skillsInView, skillsControls]);

  // Counter animation
  useEffect(() => {
    if (counterInView) {
      counterControls.start({ opacity: 1, scale: 1, transition: { duration: 0.5 } });
    } else {
      counterControls.start({ opacity: 0, scale: 0.8 }); // Reset when out of view
    }
  }, [counterInView, counterControls]);

  // Hire Me animation
  useEffect(() => {
    if (hireMeInView) {
      hireMeControls.start({ opacity: 1, y: 0, transition: { duration: 0.8 } });
    } else {
      hireMeControls.start({ opacity: 0, y: 20 }); // Reset when out of view
    }
  }, [hireMeInView, hireMeControls]);

  return (
    <Box id="about-section" sx={{ padding: 6, backgroundColor: '#222', color: '#FFBD38' }}>
      <Container>
        {/* About Me Section */}
        <motion.div
          ref={aboutMeRef}
          initial={{ opacity: 0, y: 20 }}
          animate={aboutMeControls}
        >
          <Typography
            variant="h3"
            gutterBottom
            sx={{
              textAlign: 'center',
              marginBottom: 6,
              fontWeight: 'bold',
              letterSpacing: '2px',
              textShadow: '2px 4px 8px rgba(255, 189, 56, 0)',
              fontFamily: `'Roboto', sans-serif`,
            }}
          >
            About Me
          </Typography>
        </motion.div>

        <Box display="flex" flexDirection="column" spacing={4}>
          {/* Description Section */}
          <motion.div
            ref={aboutMeRef}
            initial={{ opacity: 0, y: 20 }}
            animate={aboutMeControls}
          >
            <Paper
              elevation={6}
              sx={{
                padding: 4,
                backgroundColor: '#222',
                color: '#FFBD38',
                borderRadius: 3,
                boxShadow: '0 0 15px rgba(255, 189, 56, 0.4)',
                marginBottom: 6,
                lineHeight: 1.8,
                letterSpacing: '0.5px',
              }}
            >
              <Typography variant="body1" paragraph sx={{ fontSize: '1.2rem', fontFamily: `'Open Sans', sans-serif` }}>
              I have 4 years of experience in the IT industry, with a strong focus on DevOps, Cloud Computing, and the Software Development Life Cycle (SDLC). My expertise includes analysis, design, development, scripting, testing, automation, version control, documentation, and support.
              </Typography>
            </Paper>
          </motion.div>

          {/* Skills Section */}
          <motion.div ref={skillsRef} initial={{ opacity: 0, x: -20 }} animate={skillsControls}>
      <Paper
        elevation={6}
        sx={{
          padding: { xs: 2, sm: 4 }, // Responsive padding
          backgroundColor: '#222',
          color: '#FFBD38',
          borderRadius: 3,
          boxShadow: '0 0 15px rgba(255, 189, 56, 0.4)',
          marginBottom: 6,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            marginBottom: 2,
            textDecoration: 'underline',
            fontWeight: 'bold',
            fontFamily: `'Roboto', sans-serif`,
            fontSize: { xs: '1.5rem', sm: '2rem' }, // Responsive font size
          }}
        >
          Skills
        </Typography>
        <Box>
          {[
            { icon: <FaClock style={{ color: '#FFBD38', fontSize: '24px' }} />, label: 'Configuration Management', value: 70 },
            { icon: <FaCloud style={{ color: '#FFBD38', fontSize: '24px' }} />, label: 'Infrastructure as Code (IaC)', value: 85 },
            { icon: <FaCogs style={{ color: '#FFBD38', fontSize: '24px' }} />, label: 'CI/CD Tools', value: 90 },
            { icon: <FaGithub style={{ color: '#FFBD38', fontSize: '24px' }} />, label: 'Version Control Tools', value: 85 },
            { icon: <FaDocker style={{ color: '#FFBD38', fontSize: '24px' }} />, label: 'Containerization Orchestration', value: 80 },
          ].map((skill, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, x: -20 }}
              animate={skillsControls}
              transition={{ duration: 0.6, delay: index * 0.2 }}
            >
              <Box display="flex" alignItems="center" sx={{ marginBottom: 2, flexDirection: { xs: 'column', sm: 'row' } }}>
                <Box sx={{ marginBottom: { xs: 1, sm: 0 }, marginRight: { sm: 2 } }}>{skill.icon}</Box>
                <Box flexGrow={1}>
                  <Typography variant="body1" sx={{ fontSize: { xs: '0.9rem', sm: '1.1rem' }, fontFamily: `'Open Sans', sans-serif` }}>
                    {skill.label}:
                  </Typography>
                </Box>
                <Box sx={{ width: { xs: '100%', sm: '45%' } }}>
                  <LinearProgress
                    variant="determinate"
                    value={skill.value}
                    sx={{
                      height: 10,
                      borderRadius: 5,
                      backgroundColor: '#444', // Background color for progress bar
                      '& .MuiLinearProgress-bar': {
                        backgroundColor: '#FFBD38', // Color for the filled part
                      },
                    }}
                  />
                </Box>
              </Box>
            </motion.div>
          ))}
        </Box>
      </Paper>
    </motion.div>
        </Box>
      </Container>

      {/* Counter Section */}
      <Box sx={{ padding: 6, backgroundColor: '#222', color: '#FFBD38' }}>
      <Container>
      <motion.div
        ref={counterRef}
        initial={{ opacity: 0, scale: 0.8 }}
        animate={counterControls}
      >
        <Box
          display={{ xs: 'block', md: 'flex' }} // Stack on small screens, flex on medium and larger
          justifyContent="space-around"
          sx={{ margin: '0 auto', maxWidth: '800px' }} // Centering and limiting max width
        >
          {[
            { number: 5, label: 'Achievements' },
            { number: 10, label: 'Projects' },
            { number: 50, label: 'Mentored Students' }
          ].map((item, index) => (
            <motion.div
              key={index}
              transition={{ duration: 0.5, delay: index * 0.3 }}
            >
              <Box textAlign="center" sx={{ mb: { xs: 3, md: 0 } }}> {/* Margin bottom for small screens */}
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 'bold',
                    fontSize: { xs: '2rem', md: '2.5rem' }, // Responsive font size
                    fontFamily: `'Roboto', sans-serif`
                  }}
                >
                  {item.number}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: { xs: '1rem', md: '1.2rem' }, // Responsive font size
                    fontFamily: `'Open Sans', sans-serif`
                  }}
                >
                  {item.label}
                </Typography>
              </Box>
            </motion.div>
          ))}
        </Box>
      </motion.div>
    </Container>

        {/* Hire Me Section */}
        <motion.div
            ref={hireMeRef}
            initial={{ opacity: 0, y: 20 }}
            animate={hireMeControls}
            >
            <Box
                sx={{
                padding: { xs: 4, md: 6 }, // Responsive padding
                color: '#FFBD38',
                background: 'linear-gradient(to right, #222, rgba(0,0,0,0.4)), url(images/bg_1.jpg)',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: 4,
                textAlign: 'center',
                marginTop: { xs: 4, md: 6 }, // Responsive margin
                boxShadow: '0 0 15px rgba(255, 189, 56, 0.7)',
                }}
            >
                <Typography
                variant="h4"
                sx={{
                    fontWeight: 'bold',
                    textShadow: '2px 2px 4px rgba(255, 189, 56, 0.7)',
                    fontSize: { xs: '1.8rem', md: '2.5rem' }, // Responsive font size
                }}
                >
                More projects on <span style={{ color: '#FFBD38' }}>GitHub</span>
                </Typography>
                <Typography
                variant="h6"
                sx={{
                    marginBottom: 2,
                    fontSize: { xs: '1rem', md: '1.25rem' }, // Responsive font size
                }}
                >
                I love to solve business problems & uncover hidden Automation stories
                </Typography>
                <a
                href="https://github.com/ShubhamJangle8"
                style={{
                    display: 'inline-block',
                    marginTop: 2, // Changed for smaller devices
                    padding: '12px 36px',
                    fontSize: '1.2rem',
                    color: '#FFBD38',
                    textDecoration: 'none',
                    border: '2px solid #FFBD38',
                    borderRadius: 6,
                    transition: 'background 0.3s ease, color 0.3s ease',
                    backgroundColor: 'transparent',
                    '&:hover': {
                    backgroundColor: '#FFBD38',
                    color: '#222', // Change text color on hover
                    },
                }}
                >
                Explore GitHub
                </a>
            </Box>
            </motion.div>
      </Box>
    </Box>
  );
};

export default About;
