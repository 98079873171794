import React, { useEffect } from 'react';
import { Box, Typography, Grid, Paper, Button, Container } from '@mui/material';
import { motion, useAnimation } from 'framer-motion';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { useInView } from 'react-intersection-observer';

const Projects = () => {
  const projectData = [
    {
      title: 'Project One',
      description: 'Description of project one, detailing its features and technologies used.',
    },
    {
      title: 'Project Two',
      description: 'Description of project two, detailing its features and technologies used.',
    },
    {
      title: 'Project Three',
      description: 'Description of project three, detailing its features and technologies used.',
    },
  ];

  const [sectionRef, sectionInView] = useInView({ triggerOnce: false, threshold: 0.3 });
  const controls = useAnimation();

  useEffect(() => {
    if (sectionInView) {
      controls.start({ opacity: 1, y: 0, transition: { duration: 0.8, ease: 'easeOut' } });
    } else {
      controls.start({ opacity: 0, y: 50 }); // Reset when out of view
    }
  }, [sectionInView, controls]);

  const hoverAnimation = {
    hover: {
      scale: 1.05,
      boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)',
      transition: { duration: 0.3 },
    },
  };

  return (
    <Box
      id="project-section"
      sx={{ padding: 4, background: 'linear-gradient(to right, #222, #222)', color: '#FFBD38' }}
    >
      <Container>
        <motion.div
          ref={sectionRef}
          initial={{ opacity: 0, y: 50 }}
          animate={controls}
        >
          <Typography
            variant="h3"
            gutterBottom
            sx={{
              textAlign: 'center',
              marginBottom: 4,
              fontFamily: 'Poppins, sans-serif',
              fontWeight: 'bold',
              color: '#FFBD38',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
            }}
          >
            My Projects
          </Typography>
          <Grid container spacing={4}>
            {projectData.map((project, index) => (
              <Grid item xs={12} md={4} key={index}>
                <motion.div whileHover="hover" variants={hoverAnimation}>
                  <Paper
                    elevation={12}
                    sx={{
                      padding: 3,
                      backgroundColor: '#222',
                      color: '#FFBD38',
                      borderRadius: 3,
                      transition: 'transform 0.3s, box-shadow 0.3s',
                      boxShadow: '0 0 15px rgba(255, 189, 56, 0.7)', // Whitish glow effect
                    }}
                  >
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        fontFamily: 'Poppins, sans-serif',
                        fontWeight: 'bold',
                        color: '#FFBD38',
                      }}
                    >
                      <AccountTreeIcon sx={{ marginRight: 1, color: '#FFBD38' }} />
                      {project.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      paragraph
                      sx={{ color: '#fff', marginBottom: 3 }}
                    >
                      {project.description}
                    </Typography>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: '#FFBD38',
                        color: '#000',
                        '&:hover': { backgroundColor: '#FFC107' },
                        fontWeight: 'bold',
                      }}
                    >
                      View Project
                    </Button>
                  </Paper>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </motion.div>
      </Container>
    </Box>
  );
};

export default Projects;
